import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

export default function Downloads() {
  return (
    <div className="section">
      <Container>
        <Typography className="text-left">
          Effective training is a key factor to upgrade the knowledge of the
          resources, to help comply with cutting edge technology, and to focus
          on quality work as per project needs. Sometimes, being able to discern
          among an immediate improvement, long-term knowledge absorption, and
          performance growth can be very difficult. While Software training
          research often focuses on only one outcome for training and
          understanding, the AIS focuses on training the consultants based on an
          analysis performed to ensure the personal success of projects. We
          conduct a needs and skills analysis of the consultants before
          beginning their training. It is very important to carry out the
          systematic analysis, and establish clear objectives for consultants
          during their training and the development. Keeping the results in
          mind, the AIS analysts evaluate and communicate expectations to each
          consultant personally.
        </Typography>
      </Container>
    </div>
  );
}
